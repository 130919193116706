<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-card >
          <v-card-title class="card-title">Thông tin </v-card-title>
          <v-card-text class="card-text">
            <v-container>
              <v-row>
                <v-col cols="12" md="12" >
                  <v-row>
                  <v-col cols="12" md="12">
                    <v-img class="v-img" :src="profile.avatar" width="40px"></v-img>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field outlined hide-details="auto" v-model="profile.name" label="Tên" disabled
                      class="text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="profile.balance" label="Số tiền" disabled
                      class="text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="profile.rose" label="Hoa hồng" class="text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="profile.invite_code" label="Mã mời"
                      class="text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="profile.invited_turn" label="Số lượt đã mời"
                      class="text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="profile.area" label="Khu vực" class="text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="profile.usdt_account" label="Tài khoản USDT"
                      class="text-field"></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" md="6" v-for="product in profile.product" :key="product.id">
                    <v-text-field outlined v-model="product.name" label="Đầu tư"  class="text-field"></v-text-field>
                  </v-col> -->
                  <!-- <v-col cols="12" md="6" style="padding-top: 0; margin-top: -11px;">
                    <v-label>Tên ngân hàng</v-label>
                    <select class="change-pass-select" v-model="bank.name_bank" style="padding: 5px; height: 50px;">
                      <option :value="bank.shortName" v-for="bank in banks" :key="bank">{{ bank.shortName }}</option>
                    </select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="bank.user_name_bank" label="Tên người thụ hưởng"
                      class="text-field"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined v-model="bank.number_bank" label="Số tài khoản"
                      class="text-field"></v-text-field>
                  </v-col> -->
                  <v-col cols="12">
                    <!-- <v-btn @click="isEditing = true" color="primary" class="button" v-if="!isEditing">Sửa</v-btn> -->
                    <v-btn @click="update" color="primary" class="button">Lưu</v-btn>
                  </v-col>


                  <v-card-title class="card-title">Gói đang đầu tư</v-card-title>

                  <v-col cols="12" md="6" v-for="pro in profile.product" :key="pro._id">
                    <v-card>
                      <v-card-title>Tên gói: {{ types[pro._id?.type_product] }}</v-card-title>
                      <v-card-text>
                        <!-- <div class="d-flex justify-space-between"><span>Loại gói:</span> <span class="font-weight-black">{{ types[pro._id.type_product] }}</span></div> -->
                        <div class="d-flex justify-space-between"><span>Số tiền đầu tư:</span> <span
                            class="font-weight-black">{{ formatMoney(pro._id?.amount) }} đ</span></div>
                        <div class="d-flex justify-space-between"><span>Thời gian đầu tư:</span> <span
                            class="font-weight-black">{{ pro._id?.investment_time }}ngày</span> </div>
                        <div class="d-flex justify-space-between"><span>Phí dịch vụ:</span> <span
                            class="font-weight-black">{{ pro._id?.service_charge }}%</span></div>
                        <div class="d-flex justify-space-between"><span>Trạng thái:</span> <span
                            class="font-weight-black">{{ status[pro._id?.status] }}</span></div>
                        <div class="d-flex justify-space-between"><span>Ngày bắt đầu:</span> <span
                            class="font-weight-black">{{ formatDate(pro._id?.created_at) }}</span></div>
                        <div class="d-flex justify-space-between"><span>Ngày kết thúc:</span> <span
                            class="font-weight-black">{{ formatDate(pro._id?.end_at) }}</span></div>
                        <!-- Thêm các trường thông tin khác của sản phẩm vào đây -->
                      </v-card-text>
                    </v-card> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <!-- </div> -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card >
          <v-card-text class="card-text">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="new_password" :class="{ 'edit': isChangePasse }" label="Đổi mật khẩu"></v-text-field>
                  <v-btn class="btn btn-primary mt-2" color="primary" @click="resetPassword">Lưu</v-btn>
                </v-col>
                
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
        <v-card class="mt-5">
          <v-card-text class="card-text">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="amountRecharge" type="number"></v-text-field>
                  <v-btn @click="recharge()" color="primary" class="mt-2">Nạp tiền</v-btn>
                </v-col>
               
              </v-row>
            </v-container>
          </v-card-text>

        </v-card >
        <v-card class="mt-5">
          <v-card-text class="card-text">
            <v-container>
              <v-row>
               
                <v-col cols="12" md="12">
                  <v-text-field outlined v-model="amountWithDraw" type="number"></v-text-field>
                  <v-btn @click="withdraw()" color="primary" class="mt-2">Rút tiền</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import banks from '@/assets/bank.json'
import network from "@/core/network";
const types = [
  'CAT RED',
  "CAT SLIVER",
  'CAT BLACK',
  'CAT GOLD'
]
const status = [
  'Chờ xử lý',
  "Đã mua",
  'Dự trữ',
  'Kháng cáo'
]
export default {
  data() {
    return {
      banks: banks.banksnapas,
      new_password: '',
      profile: {},
      bank: {
        user_name_bank: '',
        number_bank: '',
        name_bank: ''
      },
      types: types,
      status: status,
      isChangePasse: false,
      isEditing: false, // Biến để kiểm tra trạng thái edit
      amountWithDraw: 0,
      amountRecharge: 0,
    };
  },
  mounted() {
    this.getUser();
  },
  computed: {
    getBankName() {
      return this.profile.bank ? this.profile.bank.name_bank : '';
    },
    getUserNameBank() {
      return this.profile.bank ? this.profile.bank.user_name_bank : '';
    },
    getNumberBank() {
      return this.profile.bank ? this.profile.bank.number_bank : '';
    }
  },
  methods: {
    getUser() {
      const id = this.$route.params.id;
      network.get(`/users/${id}`).then(res => {
        this.profile = res.data.data.profile;
        if (this.profile.bank.user_name_bank) {
          this.bank = this.profile.bank;
        }
      });
    },
    resetPassword() {
      if (!this.new_password) {
        this.$toast.open({
          message: 'Mật khẩu không được để trống',
          type: 'error',
          position: 'top',
          duration: 3000,
        });
        return false;
      }

      network.post('/users/reset_password', {
        new_password: this.new_password,
        _id: this.$route.params.id
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top',
          duration: 3000,
        });
        this.new_password = '';
        this.isChangePasse = false;
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top',
          duration: 3000,
        });
      })
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    update() {
      network.post('/users/update', {
        _id: this.$route.params.id,
        balance: this.profile.balance,
        invite_code: this.profile.invite_code,
        invited_turn: this.profile.invited_turn,
        name: this.profile.name,
        rose: this.profile.rose,
        bank: this.bank,
        usdt_account: this.profile.usdt_account
      }).then(res => {
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top',
          duration: 3000,
        });
        this.new_password = '';
        this.isChangePasse = false;
        this.isEditing = false;
        this.getUser()
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top',
          duration: 3000,
        });
      })
    },
    withdraw() {
      if (this.amountWithDraw == 0) {
        this.$toast.open({
          message: 'Số tiền lớn hơn 0',
          type: 'error',
          position: 'top',
          duration: 3000,
        });
        return false;
      }
      network.post('/requests/action/withdraw', {
        userId: this.$route.params.id,
        amount: this.amountWithDraw
      }).then(res => {
        console.log(res);

        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top'
        });
        this.getUser()
      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
      })
    },
    recharge() {
      if (this.amountRecharge == 0) {
        this.$toast.open({
          message: 'Số tiền lớn hơn 0',
          type: 'error',
          position: 'top',
          duration: 3000,
        });
        return false;
      }
      network.post('/requests/action/recharge', {
        userId: this.$route.params.id,
        amount: this.amountRecharge,
      }).then(res => {
        console.log(res);
        this.$toast.open({
          message: res.data.message,
          type: 'success',
          position: 'top'
        });
        this.getUser()

      }).catch(err => {
        this.$toast.open({
          message: err.response.data.message,
          type: 'error',
          position: 'top'
        });
      })
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    },

    formatMoney(number) {
      if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return 0;
      
    }
  },
};
</script>

<style>
/* CSS cho đẹp */
.v-img {
  margin-bottom: 10px;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.card-text {
  padding: 20px;
}

.text-field {
  margin-bottom: 15px;
}

.button {
  margin-top: 20px;
}

.change-pass-select {
  width: 100%;
  height: 40px;
  border: 1px solid #dddfe2;
  padding: 2px;
  margin-top: 6px;
  border-radius: 4px;
  background: #fff;
  color: #333333;
}
</style>
